<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="company.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>
      
      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('company.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('company.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <AvatarBanner v-if="isAvatarBannerVisible" 
          v-model="avatarBanner" 
          :readOnly="isAvatarBannerReadOnly" 
          :baseAvatarIcon="['fad','chart-network']" transforms="shrink-6 left-2" @status="avatarBannerStatus"
        />
      
        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <b-form-group v-if="isParentCompanyVisible" label-for="parent">
          <b-input-group>
            <b-form-input id="parent" type="text"
              v-model="parent.name" readonly>
            </b-form-input>
            <b-input-group-append v-if="!(isParentCompanyReadOnly)">
              <b-button :disabled="company.type === 'Primary'" size="sm" @click="modalRemove">{{ $t('button.remove') }}</b-button>
              <b-button v-if="canList()" :disabled="company.type === 'Primary'" size="sm" variant="info" @click="modalSelect">{{ $t('button.select') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('company.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('company.field.name')"
                    data-vv-name="company.name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="company.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :autofocus="true"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'company.name')"
                    trim 
                    @keydown.native="keydownHandler">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('company.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="company.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="company.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isTypeVisible" cols="12" md="8" class="pr-0">
              <b-form-group id="field-type" :label="$t('company.field.type')" label-for="type">
                <multiselect v-model="company.type" class="custom-dropdown-options enable-option-icon"
                  :max-height="300"
                  :options="filteredCompanyTypes.filter(i => i.num >= 0).map(i => i.value)"
                  :custom-label="getCompanyTypeOptionLabel"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false"
                  :disabled="isTypeReadOnly">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="company.type == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ getCompanyTypeOptionLabel(props.option) }}</span>
                  </template>
                </multiselect>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showTypeError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('company.type') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['type'] != null">
              <b-col v-for="(field, index) in customFieldMap['type']" :key="'type'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="company.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
        </b-row>
        </div>
        
        <b-form-group v-if="isLocationVisible">
          <label class="mr-1">{{ $t(`project.field.location`) }}</label>
          <button v-if="!isLocationReadOnly" id="LOCATION_ADD" class="btn-action" @click="locSelectorOpen"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="LOCATION_ADD"
              placement="top"
              triggers="hover"
              :content="$t('company.button.location_add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="location" :readOnly="isLocationReadOnly">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="canView('LOCATION') ? locationBadgeClick(item.uuId) : ''"
                :text="item.name" 
                variant="primary" 
                :pillable="!!item.pillable" :key="index" 
                :readOnly="isLocationReadOnly" />
              </template>
          </BadgeGroup>
        </b-form-group>

        <template v-if="customFieldMap['location'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['location']" :key="'location'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
        
        <b-form-group v-if="isTagVisible">
          <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
        </b-form-group>

        <template v-if="customFieldMap['tags'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
          
        <b-form-group v-if="isNoteVisible">
          <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
        </b-form-group>

        <template v-if="customFieldMap['notes'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="company[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="viewStaff" style="margin-right: auto" v-if="exists && canView('STAFF')">
          {{ $t('button.view_staff') }}
        </b-button>
        <b-button v-if="!isAccessDenied && (canEdit() || !exists)" :disabled="disableOk" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!-- company selector -->
    <CompanySelectorModalForAdmin v-if="modalShowSelector"
      :show.sync="modalShowSelector" 
      :preselected="parent != null? parent.uuId : null"
      :selectorTitle="$t('company.title_parent_selector')"
      :exclude="company.uuId"
      nonAdmin
      singleSelection
      @ok="modalSuccessSelector"
    />
    
    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="state.locSelectorShow"
      :show.sync="state.locSelectorShow" 
      :entityService="locationUtil" 
      entity="LOCATION"
      :preselected="locationEditId"
      nonAdmin
      @cancel="locSelectorCancel"
      @ok="locSelectorOk"
    />
  
    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="COMPANY" :customFields="customFields" links="LOCATION,NOTE,TAG"/>
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
      <!-- staff selector -->
      <StaffSelectorModalForAdmin v-if="showStaffSelector"
        :show.sync="showStaffSelector" 
        :companies="[company]"
        nonAdmin
        hideOkBtn
      />
    </template>    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateLocation } from '@/helpers/location';
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';

import { companyService, companyLinkLocationService,
         companyLinkTagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { locationUtil } from '@/views/management/script/location';
import Multiselect from 'vue-multiselect';

export default {
  name: 'CompanyModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    CompanySelectorModalForAdmin: () => import('@/components/modal/CompanySelectorModalForAdmin'),
    Multiselect
  },
  props: {
    id:        { type: String,   default: `COMPANY_NEW_${strRandom(5)}` },
    parentData:{ type: [String, Object],   default: null },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    masterCompany: { type: Object, default: null }
  },
  data() {
    return {
      permissionName: 'COMPANY',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      showStaffSelector: false,
      modalShowSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false,
        historyShow:         false,
        locSelectorShow:     false,
        noteShow:            false
      },
      company: {
        uuId:               null,
        name:               null,
        type:               null,
        identifier:         null,
        color:              null,
        readOnly:           false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      parent: {
        uuId:               null,
        name:               null
      },
      locationEditId: null,
      location: [],
      tags: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      updatedColor: null,
      isAccessDenied: false,

      typeOptions: [],
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    this.originCompany = null;
    if(this.id  == null || this.id.indexOf('COMPANY_NEW_') != -1) {
      // new company, set the parent to the selected company
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
        }
        else {
          // const self = this;
          companyService.query([{ uuId: this.parentData}]).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              this.parent.uuId = data[0].uuId;
              this.parent.name = data[0].name;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
          });
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originLocation = [];
    this.originTags = [];
    this.originNotes = [];
    this.locationUtil = locationUtil;
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.processWhenShowModal(true);
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originLocation = null;
    this.originCompany = null;
    this.originTags = null;
    this.originNotes = null;
    this.locationUtil = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `COMPANY_FORM_${this.id}`;
    },
    exists() {
      return this.id && !this.id.startsWith('COMPANY_NEW_');
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.company.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'company.name');
    },
    showTypeError() {
      return fieldValidateUtil.hasError(this.errors, 'company.type');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title: this.$t('company.title_new');
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    locationTitle() {
      return this.locationId && this.locationId.indexOf('LOCATION_NEW') == -1? this.$t('location.title_detail'): this.$t('location.title_new');
    },
    isAvatarBannerVisible() {
      return this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef']) && 
      ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists);
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']));
    },
    isParentCompanyVisible() {
      //ParentCompany is mandatory field in business logic
      return this.canView(this.permissionName, ['COMPANY'])
    },
    isParentCompanyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['COMPANY']))
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isTypeVisible() {
      //Type is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['type'])
    },
    isTypeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['type'])) 
      || this.company.type === 'Primary'
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isLocationVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('LOCATION') && this.canView(this.permissionName, ['LOCATION']) && ((!this.exists && this.canEdit(this.permissionName, ['LOCATION']))
      || this.exists)
    },
    isLocationReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['LOCATION'])
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    filteredCompanyTypes() {
      return this.typeOptions
        .filter(t => (this.company.type === 'Primary' && t.value === 'Primary') 
                      || (this.company.type !== 'Primary' && t.value !== 'Primary'))
    },
    disableOk() {
      return (this.original.readOnly && this.company.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    },
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue);
      }
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      await getCustomFieldInfo(this, 'COMPANY');
      this.$validator.resume();
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      this.state.modalShow = newValue;
      this.state.autoScheduleExpanded = false;
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.notes = [];
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      if(this.id.indexOf('COMPANY_NEW_') === -1) {
        this.companyGet(this.id);
      } else {
        
        if (newValue) {
          const requiredFields = ['name', 'type', 'parent']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        
        } else {
          this.isAccessDenied = false;
        }
        this.resetCompanyProperties();
      }
    },
    getDisplayNameOfProperty(val) {
      if (val == 'parent') {
        return this.$t('company.field.parent_department');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`company.field.${val}`);
      }
    },
    locSelectorOpen() {
      this.locationEditId = null;
      this.state.locSelectorShow = true;
    },
    locSelectorOk({ details }) {
      const locations = JSON.parse(JSON.stringify(this.location));
      
      if (details.length > 0) {
        const newLocationsToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = locations.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const location = locations[foundIndex];
            location.uuId = r.uuId;
            location.name = r.name;
            newLocationsToReplacePreselected.push(location);
            locations.splice(foundIndex, 1);
          } else {
            newLocationsToReplacePreselected.push({ uuId: r.uuId, name: r.name })
          }
        }

        if (this.locationEditId != null) {
          const foundIndex = locations.findIndex(i => i.uuId == this.locationEditId);
          if (foundIndex != -1) {
            locations.splice(foundIndex, 1, ...newLocationsToReplacePreselected);
          } else {
            locations.push(...newLocationsToReplacePreselected);
          }
        } else {
          locations.push(...newLocationsToReplacePreselected);
        }
      } else if (this.locationEditId != null) {
        //When no selection is made, remove the preselected one from the existing locations
        locations.splice(locations.findIndex(j => j.uuId === this.locationEditId), 1);
      }
      this.location.splice(0, this.location.length, ...locations);

      this.locationEditId = null;
    },
    locSelectorCancel(deletedIds) {
      for (let i = this.location.length - 1; i >= 0; i--) {
        const loc = this.location[i];
        if (loc && deletedIds.findIndex((l) => { return l.uuId === loc.uuId }) !== -1) {
          this.location.splice(i, 1);
        }
      }
      
      for (let i = this.originLocation.length - 1; i >= 0; i--) {
        const loc = this.originLocation[i];
        if (loc && deletedIds.findIndex((l) => { return l.uuId === loc.uuId }) !== -1) {
          this.originLocation.splice(i, 1);
        }
      }
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "COMPANY"}).then(value => {
        this.modelInfo = value.COMPANY.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });

      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null && propertyList.CompanyTypeEnum != null) {
            const obj = propertyList.CompanyTypeEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.typeOptions = list
          }
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    findParent(data, id, parent) {
      for (var dept of data) {
        if (dept.uuId === id) {
          if (parent !== null) {
            this.parent.uuId = parent.uuId;
            this.parent.name = parent.name;
            return;
          }
        }
        
        if (typeof dept.companyList !== 'undefined') {
          this.findParent(dept.companyList, id, dept);
        }
      }
    },
    companyParentGet(id) {
      const self = this;
      this.parent.name = '';
      this.parent.uuId = null;
      companyService.tree(false).then((response) => {  
        const treeData = response.data[response.data.jobCase];
        self.findParent(treeData, id, null);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    companyGet(id) {
      companyService.query([{ uuId: id}], ['LOCATION', 'TAG', 'NOTE']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return;
        }
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.company;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }

      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      this.companyParentGet(this.company.uuId);
      
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }
      
      if (data.locationList && data.locationList.length > 0) {
        this.location = cloneDeep(data.locationList);
        this.originLocation = cloneDeep(data.locationList);
      }
      else {
        this.location = [];
        this.originLocation = [];
      }
      
       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }
      
      if (this.parentData != null) {
        this.parent.name = this.parentData.name;
        this.parent.uuId = this.parentData.uuId;
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }

      this.originCompany = cloneDeep(s);
      this.originCompany.avatarRef = data.avatarRef;
      this.originCompany.bannerRef = data.bannerRef;
    },
    modalSelect() {
      this.modalShowSelector = true;
    },
    modalRemove() {
      this.parent.uuId = this.masterCompany.uuId;
      this.parent.name = this.masterCompany.name;
    },
    ok() {
    
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.company[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
          this.companySubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async companySubmit() {
      const data = cloneDeep(this.company);
      
      data['avatarRef'] = this.avatarBanner.avatarId ? this.avatarBanner.avatarId : '00000000-0000-0000-0000-000000000000';
      data['bannerRef'] = this.avatarBanner.bannerId ? this.avatarBanner.bannerId : '00000000-0000-0000-0000-000000000000';
      
      let mode = 'update';
      let successMsg = this.$t('company.update');
      if(this.id.indexOf('COMPANY_NEW_') !== -1) {
        delete data['uuId'];
        data['parent'] = this.parent.uuId;
        mode = 'create';
        successMsg = this.$t('company.create');
      }

      const storeCompanyPayload = cloneDeep(data); //have a clone to pertain complete data set for later store update.
      let uuId = null;
      //Skip updating company if there is no change in company properties.
      let hasChanged = false;
      if (mode != 'create') {
        hasChanged = this.removeUnchangedCompanyProperties(data);
      }

      if (mode == 'create' || hasChanged) {
        uuId = await this.companyPost(mode, data, this.$t(`company.${mode}`));
        if (uuId == null) { 
          //Error occurs when uuId is null.
          //Stop from proceeding further.
          return; 
        }
      } else {
        uuId = data.uuId;
      }
      storeCompanyPayload.uuId = uuId;
      
      // update the displayed company
      if (mode === 'update' && uuId == this.$store.state.company.uuId) {
        storeCompanyPayload.locationList = this.location;
        document.title = `Projectal - ${storeCompanyPayload.name}`;
        this.$store.dispatch("company/update", storeCompanyPayload, { root: true });
      }

      let hasError = false;
      
      if (mode === 'update' && this.parentChanged()) {
        // change the parent
        if (this.parentData !== null) {
          await this.unlinkParent(); 
        }
        
        await this.linkParent();
      } 
      
      // save the color in the profile
      this.updatedColor = data.color;
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(data.uuId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`company.${mode}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      if (!this.isLocationReadOnly) {
        const locationResult = await updateLocation(uuId, companyLinkLocationService, this.originLocation, this.location);
        if (locationResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`company.${mode}_partial`);
          if (locationResult.errorCodes.length > 0) {
            if (locationResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('location.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(uuId, companyLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`company.${mode}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg, parent: this.parent });
      }
    },
    parentChanged() {
      // is the parent uuId different
      return (this.parentData === null && this.parent.uuId !== null) ||
                 (this.parentData !== null && this.parentData.uuId !== this.parent.uuId);
    },
    async companyPost(method, data/**, successMsg */) {
      this.state.isSubmitting = true;
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      const result = await companyService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
      
      this.state.isSubmitting = false;
      return result;
    },
    async linkParent() {
      if (this.parent.uuId !== null) {
        const parentData = {};
        parentData['uuId'] = this.parent.uuId;
        parentData['companyList'] = [{'uuId': this.company.uuId}];
        await this.companyPost('link', parentData, this.$t('company.link'));
      }
    },
    async unlinkParent() {
      if (this.parentData !== null) {
        const parentData = {};
        parentData['uuId'] = this.parentData.uuId;
        parentData['companyList'] = [{'uuId': this.company.uuId}];
        await this.companyPost('unlink', parentData, this.$t('company.unlink'));
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `company.${feedback.args[0]}`;
          const args = [this.$t(`company.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetCompanyProperties() {
      const keys = Object.keys(this.company);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.company[keys[i]] = customField.def;
            continue;
          }
        }
        this.company[keys[i]] = null;
      }
      
      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;
      this.location = [];
      
      // set the parent to the selected company
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
        }
        else {
          const self = this;
          this.parent.name = '';
          this.parent.uuId = null;
          companyService.query([{ uuId: this.parentData}]).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              self.parent.uuId = data[0].uuId;
              self.parent.name = data[0].name;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
          });
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
      this.originCompany = null;
      this.originNotes = [];
      this.notes = [];
    },  
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
      }
    },
    modalSuccessSelector({ details }) {
      this.parent.uuId = details[0].uuId ? details[0].uuId : this.masterCompany.uuId;
      this.parent.name = details[0].name ? details[0].name : this.masterCompany.name;
      this.modalShowSelector = false;
    },
    modalCancelSelector() {
      this.modalShowSelector = false;
    },
    locationBadgeRemove: function(index) {
      this.location.splice(index,1);
    },
    locationBadgeClick(id) {
      this.locationEditId = id;
      this.state.locSelectorShow = true;
    },
    locationModalSuccess(result) {
      const location = result.data;
      const index = this.location.findIndex(i => i.uuId === location.uuId);
      if (index !== -1) {
        this.location.splice(index, 1, location);
      }
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedCompanyProperties(data) {
      //Remove those properties whose value is not changed in provided data against original company.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalCompany = this.originCompany;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalCompany[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    viewStaff() {
      this.showStaffSelector = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    },
    getCompanyTypeOptionLabel(value) {
      return this.filteredCompanyTypes.find(i => i.value === value)?.text || value;
    },
  }
}
</script>
