<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="$t('task.auto_assign.title')" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      content-class="shadow"
      no-close-on-backdrop
    >
      <div class="selected-text">{{ $t('task.auto_assign.num_selected', [tasks.length])}}</div>
      <div class="selected-text d-inline-block mr-2 mb-2">{{$t(isGenericStaff? 'task.auto_assign.num_generic_staff_selected' : 'task.auto_assign.num_staff_selected', [staff.length, !hideStaffLengthLabel ? $t('task.auto_assign.allstaff') : ''])}}</div>
      <b-button v-if="canList()" class="staff-button" variant="secondary" @click="chooseStaff">{{ $t('button.choose_staff') }}</b-button>
      
      <hr class="solid">

      <b-form-checkbox class="setting-checkbox" v-model="settings.skip_already_started" size="lg" name="allow_over_alloc">
        {{ $t('task.auto_assign.skip_already_started') }}
      </b-form-checkbox>
  
      <b-form-checkbox class="setting-checkbox" v-model="settings.skip_already_assigned" size="lg" name="allow_over_alloc">
        {{ $t('task.auto_assign.skip_already_assigned') }}
      </b-form-checkbox>

      <b-form-checkbox class="setting-checkbox" v-model="settings.replace_generic_only" size="lg" name="allow_over_alloc">
        {{ $t('task.auto_assign.replace_generic_only') }}
      </b-form-checkbox>
  
      <b-form-checkbox class="setting-checkbox" v-model="settings.allow_over_alloc" size="lg" name="allow_over_alloc">
        {{ $t('recommendation.allow_over_alloc') }}
      </b-form-checkbox>
  
      <hr class="solid">
      <div class="skill-match">{{$t('task.auto_assign.skill_matching')}}</div>
      <b-form-checkbox class="setting-checkbox" v-model="settings.match_staff_based_on_skills" size="lg" name="match_staff_based_on_skills">
        {{ $t('recommendation.match_staff_based_on_skills') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="true" v-model="settings.include_staff_exact_skill" size="lg" name="include_staff_exact_skill">
        {{ $t('recommendation.include_staff_exact_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="!settings.match_staff_based_on_skills" v-model="settings.include_staff_higher_skill" size="lg" name="include_staff_higher_skill">
        {{ $t('recommendation.include_staff_higher_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent-two setting-checkbox" :disabled="!settings.match_staff_based_on_skills || !settings.include_staff_higher_skill" v-model="settings.adjust_task_duration_higher" size="lg" name="adjust_task_duration_higher">
        {{ $t('recommendation.adjust_task_duration') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent setting-checkbox" :disabled="!settings.match_staff_based_on_skills" v-model="settings.include_staff_lower_skill" size="lg" name="include_staff_lower_skill">
        {{ $t('recommendation.include_staff_lower_skill') }}
      </b-form-checkbox>

      <b-form-checkbox class="indent-two setting-checkbox" :disabled="!settings.match_staff_based_on_skills || !settings.include_staff_lower_skill"  v-model="settings.adjust_task_duration_lower" size="lg" name="adjust_task_duration_lower">
        {{ $t('recommendation.adjust_task_duration') }}
      </b-form-checkbox>


      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="staffSelectorShow"
      :show.sync="staffSelectorShow" 
      :projectIds="projectId ? [projectId] : []"
      :companies="companies"
      :selectedStaff="staffUuids"
      nonAdmin
      @ok="staffSelectorOk"
    />
  </div>
</template>

<script>
import { allocationProfileService, staffService } from '@/services';

export default {
  name: 'AutoAssignStaffModal',
  components: {
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin')
  },
  props: {
    show:         { type: Boolean, required: true },
    tasks:        { type: Array, required: true },
    companies:    { type: Array, default: () => { return []; } },
    projectId:    { type: String, default: null }
  },
  data() {
    return {
      permissionName: "STAFF",
      modalShow: false,
      userId: null,
      user: null,
      staff: [],
      totalStaff: 0,
      settings: {
        skip_already_started: true,
        skip_already_assigned: true,
        replace_generic_only: true,
        allow_over_alloc: false,
        match_staff_based_on_skills: true,
        include_staff_exact_skill: true,
        include_staff_higher_skill: true,
        adjust_task_duration_higher: false,
        include_staff_lower_skill: true,
        adjust_task_duration_lower: false
      },
      staffSelectorShow: false,
      isGenericStaff: false,
      hideStaffLengthLabel: false
    }
  },
  created() {
    this.settings_template = JSON.parse(JSON.stringify(this.settings));
  },
  beforeDestroy() {
    this.settings_template = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        const self = this;
        if (newValue) {
          this.loadStaff();
          this.userId = this.$store.state.authentication.user.uuId;
          allocationProfileService.list(this.userId).then((response) => {  
            const profileData = response.data[response.data.jobCase];
            if (profileData.length === 0) {
              self.createAllocationProfile();
            }
            else {
              self.settings = profileData[0];
              self.rectifyInvalidSettings();
            }
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        } 
        this.modalShow = newValue;
        this.isGenericStaff = false;
        this.hideStaffLengthLabel = false;
      }
    }
  },
  computed: {
    staffUuids() {
      return this.staff.map(s => { return s.uuId });
    },
    companyrule() {
      if (this.companies.length !== 0 || (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds)) {
        const companies = this.companies.length > 0 ? this.companies.map(c => { return c.uuId }) : this.$store.state.company.filterIds;
        const companyrule = [['STAFF.COMPANY.uuId', 'within', companies.join('|')]];
        return companyrule
      }
      return null;
    }
  },
  methods: {
    rectifyInvalidSettings() {
      for (const prop in this.settings_template) {
        if (typeof this.settings[prop] != 'boolean') {
          this.settings[prop] = this.settings_template[prop];
        }
      }
    },
    createAllocationProfile() {
      allocationProfileService.create([{ settings: this.settings }], this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadStaff() {
      this.staff = await staffService.list({start: 0, limit: -1, ksort: 'lastName', order: 'incr', active: true, inactive: false, filter: this.companyrule}).then((response) => {
        var staffList = [];
        for (let i = 0; i < response.data.length; i++) {
          staffList.push({ uuId: response.data[i].uuId });
        }
        return staffList;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return [];
      });
      this.totalStaff = this.staff.length;
    },
    ok() {
      allocationProfileService.update([this.settings], this.userId)
      .then((response) => {  
        self.profileData = response.data;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.$emit('update:show', false);
      this.$emit('success', { settings: this.settings, staffList: this.staff } );
    },
    staffSelectorOk({ details }) {
      this.staff = details.map(i => { return { uuId: i.uuId }});
      if(details && details.length > 0) {
        this.isGenericStaff = details.some(i => i.genericStaff == true);
      } else {
        this.isGenericStaff = false;
      }
    },
    chooseStaff() {
      this.staffSelectorShow = true;
      this.hideStaffLengthLabel = true;
    }
  }
}
</script>

<style lang="scss">
.indent {
  margin-left: 1.5em;
}

.indent-two {
  margin-left: 3em;
}

.setting-checkbox {
  font-size: 1em !important;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 2.3 !important;
}

.selected-text,
.skill-match {
  font-weight: bold;
}

.staff-button {
  float: right;
}
</style>